<template>
  <v-expansion-panels
    accordion
    multiple
    tile
    class="accordion filter-sidebar"
    :value="openPanelIndexes"
  >
    <v-expansion-panel>
      <v-expansion-panel-header>
        {{ $t('documentFilter.filters') }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <filter-tree
          filter-type="document"
          store-getter="documentFilters"
          store-setter="setDocumentFilters"
          :allow-select-all="true"
          :sync-query-string="false"
          :open-nodes="['CON', 'DOC']"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import FilterTree from '@/components/FilterTree.vue';

export default {
  components: {
    FilterTree,
  },

  data() {
    return {
      openPanelIndexes: [0],
    };
  },

  // methods: {
  //   onFilterChanged(selectedFilters) {
  //     this.$store.dispatch("setAgendaFilters", selectedFilters);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
// TODO, needs to be removed in future
.accordion {
  border-right: 1px solid #eee;
}
</style>
