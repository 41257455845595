<template>
  <div>
    <v-tooltip
      v-model="show"
      max-width="640"
      top
      :position-x="posx"
      :position-y="posy"
      absolute
    >
      <slot />
    </v-tooltip>
  </div>

</template>
<script>

export default {
  name: 'AgendaTooltip',
  props: ['posx', 'posy', 'show'],
};
</script>
