<template>
  <div>
    <treeview
      :items="itemsAll"
      :openItems="openNodes"
      v-model="itemsChecked"
      @input="onCheckboxClicked"
      selected-color="teal lighten-1"
      item-key="id"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Treeview from '@/components/Treeview.vue';
import replace from '../filters/replace';
import capitalize from '../filters/capitalize';

export default {
  components: {
    Treeview,
  },
  props: {
    filterType: {
      type: String,
      required: true,
    },
    openNodes: {
      type: Array,
      required: false,
    },
    queryStringRoute: {
      type: String,
      required: false,
    },
    addRootNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    syncQueryString: {
      type: Boolean,
      required: true,
      default: false,
    },
    allowSelectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    dispatchOnCheckboxClicked: {
      type: String,
      required: false,
    },
    storeGetter: {
      type: String,
      required: true,
    },
    storeSetter: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    tmp: true,
  }),

  filters: {
    replace,
    capitalize,
  },

  computed: {
    ...mapGetters({
      filters: 'userFilters',
    }),

    itemsAll() {
      const filters = [...this.filters[this.filterType]];

      if (this.addRootNode) {
        return [
          {
            id: 0,
            name: this.$t('generic.selectAll'),
            children: filters,
          },
        ];
      }
      return this.sanitizeFilterNames(filters);
    },

    itemsChecked: {
      get() {
        return this.$store.getters[this.storeGetter];
      },
      set(checkedFilters) {
        this.$store.dispatch(this.storeSetter, checkedFilters);
      },
    },
  },

  methods: {
    ...mapState(['searchParameters']),

    sanitizeFilterNames(treeObject) {
      const sanitizeNames = (obj) => {
        const editObj = obj;
        if (Array.isArray(editObj)) {
          for (let j = 0; j < editObj.length; j++) {
            editObj[j] = sanitizeNames(editObj[j]);
          }
        } else {
          editObj.name = this.$options.filters.capitalize(this.$options.filters.replace(editObj.name, 'vaste commissie voor ', ''));
          if (editObj.children) {
            for (let j = 0; j < editObj.children.length; j++) {
              editObj.children[j] = sanitizeNames(editObj.children[j]);
            }
          }
        }
        return editObj;
      };
      return sanitizeNames(treeObject);
    },

    onCheckboxClicked() {
      if (this.dispatchOnCheckboxClicked) {
        this.$store.dispatch(this.dispatchOnCheckboxClicked);
      }
    },
  },

  mounted() {
    if (this.syncQueryString && this.$route.query.filters) {
      this.itemsChecked = this.$route.query.filters.split(',');
      this.onCheckboxClicked();
    }
  },
};
</script>

<style lang="scss" scoped>
  .treeview ::v-deep .line{
    margin-bottom: 16px;
  }
</style>
