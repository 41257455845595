<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          class="ma-0 pa-0"
        >
          <v-expansion-panels
            accordion
            multiple
            tile
            class="accordion filter-sidebar"
            :value="openPanelIndexes"
            style="z-index: inherit;"
          >
            <v-expansion-panel>
              <v-expansion-panel-header> {{ $t('agenda.filters') }} </v-expansion-panel-header>
              <v-expansion-panel-content>
                <filter-tree
                  filter-type="agenda"
                  query-string-route="agenda"
                  store-getter="agendaFilters"
                  store-setter="setAgendaFilters"
                  dispatch-on-checkbox-clicked="getAllAgendas"
                  :open-nodes="['COM']"
                  :allow-select-all="true"
                  :sync-query-string="true"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FilterTree from '@/components/FilterTree.vue';

export default {
  components: {
    FilterTree,
  },

  data() {
    return {
      openPanelIndexes: [0],
    };
  },

  // methods: {
  //   onFilterChanged(selectedFilters) {
  //     this.$store.dispatch("setAgendaFilters", selectedFilters);
  //   },
  // },
};
</script>

<style lang="scss" scoped>
// TODO, needs to be removed in future
.accordion {
  border-right: 1px solid #eee;
}
</style>
