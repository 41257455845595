<template>
  <div id="document">
    <base-layout>
      <template v-slot:drawer-left>
        <DocumentFilterSidebar />
      </template>

      <template v-slot:identity>
        <a
          href="/document"
          class="d-flex align-start"
        >
          <img
            style="cursor: pointer;"
            src="../../../assets/logo-transparant-groot.png"
            alt="Polpo - Politieke monitoring"
            height="42"
          >
        </a>
      </template>

      <template v-slot:searchbar>
        <search-bar
          on-search-action="getAllDocuments"
          set-query-action="setDocumentQuery"
          search-query="searchParameterQuery"
          :placeholder="$t('documentFilter.searchInDocuments')"
        />
      </template>

      <template v-slot:main-content>
        <pre>{{ documentFilters }}</pre>
      </template>

      <template v-slot:drawer-right>
        <div>Sidebar</div>
      </template>
    </base-layout>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseLayout from '@/components/BaseLayout.vue';
import SearchBar from '@/components/SearchBar.vue';
import DocumentFilterSidebar from '../components/DocumentFilterSidebar.vue';

// Vuex

export default {
  name: 'Document',

  components: {
    BaseLayout,
    DocumentFilterSidebar,
    SearchBar,
  },

  watch: {},

  computed: {
    ...mapGetters({
      documentFilters: 'documentFilters',
    }),
  },

  methods: {},

  mounted() {
    this.$store.dispatch('getFilterTypesForUser');
    this.$store.dispatch('getPreferences');
    // this.parseQueryParams(this.$route.query);
  },
};
</script>

<style scoped lang="scss"></style>
